import logo from "./logo.svg";
import "./App.css";
import {
  Button,
  Form,
  Card,
  Table,
  DropdownButton,
  InputGroup,
  FormControl,
  Input,
} from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";

function App() {
  const [administrations, setAdministration] = useState("");
  const [searchedContact, setSearchedQuery] = useState({});
  const [foundCustomer, setFoundCustomer] = useState({});
  const [totalPrice, setTotalPrice] = useState();
  const [invoiceData, setInvoiceData] = useState({
    sales_invoice: {
      contact_id: "",
      reference: "",
      details_attributes: [],
      prices_are_incl_tax: false,
      first_due_interval: 30,
    },
  });

  useEffect(() => {
    var config = {
      method: "get",
      url: "https://moneybird.com/api/v2/administrations?access_token=pr9hOUQfyoLZw29mSoqyA5QMthWIrpQBid3MEnSN9qI",
    };

    axios(config).then(function (response) {
      //console.log(response.data[0]);

      setAdministration(response.data[0]);
    });
  }, []);

  function ChangeDueInterval(value) {
    invoiceData.sales_invoice.first_due_interval = value;
  }

  function SetCustomer(value) {
    setFoundCustomer(value);

    invoiceData.sales_invoice.contact_id = value.id;

    console.log(value);

    console.log(foundCustomer);
    console.log(invoiceData);
    document.getElementById("customerList").style.display = "none";
  }

  function SearchContact(value) {
    document.getElementById("customerList").style.display = "block";
    var config = {
      method: "get",
      url:
        "https://moneybird.com/api/v2/276567090616337995/contacts?query=" +
        value +
        "&access_token=pr9hOUQfyoLZw29mSoqyA5QMthWIrpQBid3MEnSN9qI",
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        setSearchedQuery(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function ConvertToMoneybird() {
    var JSONdata = document.getElementById("JSONMoneybird").value;
    JSONdata = JSONdata.replace(/[\n\r]+/g, '');
    JSONdata = JSON.parse(JSONdata.slice(0,JSONdata.length - 2) + JSONdata.slice(JSONdata.length - 1));
    console.log(JSONdata);
    let allPrice = 0.0;
    let reference = "";
    JSONdata.map((element) => {
      console.log(element.price);
      let newPrice = parseFloat(element.price.split(".").join("").replace(/,/g, "."));
      console.log(element.price);

      allPrice = parseFloat(allPrice) + parseFloat(newPrice);
      element.price = parseFloat(newPrice);
      invoiceData.sales_invoice.reference = element.reference;
    });
    setTotalPrice(allPrice);
    
    invoiceData.sales_invoice.details_attributes = JSONdata;
    setInvoiceData(invoiceData);
    console.log(invoiceData);
  }

  function SendToMoneybird() {

    var data = invoiceData;
    
    var config = {
      method: 'post',
      url: 'https://drukwerkcalculator.herokuapp.com/moneybird',
      data : data
    };

    console.log(config)
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      window.location.reload();
    })
    .catch(function (error) {
      alert("Even opnieuw proberen of mij even bellen ;), geen connectie met backend " + error);
    });
    
      
  }

  return (
    <>
      <Card className="Cardhome" body>
        <h1>{administrations.name}</h1>
      </Card>

      <Card className="Cardhome" body>
        <h3>Convert invoice details</h3>
        <Form>
          <InputGroup>
            <InputGroup.Text>Invoice data</InputGroup.Text>
            <FormControl
              id="JSONMoneybird"
              as="textarea"
              aria-label="With textarea"
              rows="7"
              onChange={(e) => ConvertToMoneybird(e)}
            />

          </InputGroup>
        </Form>
      </Card>

      <Card className="Cardhome" body>
        <h3>Search customer</h3>
        

        <InputGroup
          className="col-6"
          onChange={(e) => SearchContact(e.target.value)}
        >
          <FormControl
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
        </InputGroup>
        <Card id="customerList">
          {Object.keys(searchedContact).map((search, key) => {
            return (
              <>
                <div
                  className="customerList"
                  key={searchedContact[key].id}
                  value={searchedContact[key].id}
                >
                  <b>{searchedContact[key].company_name}</b>&nbsp; &nbsp; &nbsp;
                  <i>{searchedContact[key].email}</i>&nbsp; &nbsp; &nbsp;
                  <Button onClick={() => SetCustomer(searchedContact[key])}>
                    Selecteer
                  </Button>
                </div>
              </>
            );
          })}
        </Card>
      </Card>

      <Card body className="Cardhome">
        <h3>Invoice</h3>
        <div className="customerDetails">
          <p>
            <b>{foundCustomer.company_name}</b>
          </p>
          <p>{foundCustomer.email}</p>
          <p>{foundCustomer.city}</p>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Description</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.sales_invoice.details_attributes.map((orderline) => {
              return (
                <>
                  <tr>
                    <td>{orderline.amount}</td>
                    <td>{orderline.description}</td>
                    <td>{orderline.price}</td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td><b>Total</b></td><td></td>
              <td><b>{totalPrice}</b></td>
            </tr>
          </tbody>
          
            
          
        </Table>

      </Card>
      <Card className="Cardhome" body>
        <Form.Label>Due days invoice</Form.Label>
        <Form.Control
          type="number"
          defaultValue={invoiceData.sales_invoice.first_due_interval}
          onChange={(e) => ChangeDueInterval(e.target.value)}
        />
        <Form.Text id="passwordHelpBlock" muted>
          Amount of days customer gets before he receives a reminder.
        </Form.Text>
        <br /><br />

        <Button style={{width:"100%", height: "100px"}} onClick={() => SendToMoneybird()}>Flap flap flap...</Button>
      </Card>
      <br />
      <hr />
      <p>Versie 2.0</p>
    </>
  );
}

export default App;
